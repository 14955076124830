body {
	margin: 0;
	font-family: 'Open Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	color: var(--font-color);

	background-color: var(--bg-color);
	transition: background-color 0.25s ease, color 0.25s ease;;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}

:root {
	--bg-color: rgb(230, 230, 230);
	--font-color: #000;
	--border-color: #323232;
	--light-bg-color: rgb(200, 200, 200);
	--green-bg-color: #55efc4;

	--set-color: rgba(198, 220, 228, 0.75);
	--set-color-fix: #C6DCE4;

	--rep-color: rgba(242, 209, 209, 0.75);
	--rep-color-fix: #F2D1D1;

	--time-color: rgba(125, 157, 156, 0.07);
	--time-color-fix: #7D9D9C;

	--border-width: 3px;
}

[data-theme="dark"] {
	--bg-color: rgb(16, 16, 16);
	--font-color: #fff;
	--border-color: rgb(200, 200, 200);
	--light-bg-color: rgb(64, 64, 64);
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
	scrollbar-width: auto;
	scrollbar-color: #434242 rgba(0, 0, 0, 0);
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 4px;
}

*::-webkit-scrollbar-track {
	background: rgba(0, 0, 0, 0);
}

*::-webkit-scrollbar-thumb {
	background-color: #434242;
	border-radius: 10px;
	border: 2px solid rgba(0, 0, 0, 0);
}