.app {
	display: grid;
	grid-template-columns: 0.75fr 1fr;
	grid-template-rows: 1fr;
	grid-column-gap: 0;
	grid-row-gap: 0;

	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.middleborder {
	border-left: var(--border-color) var(--border-width) solid;
}

.exercise {
	text-align: center;
	position: relative;
	overflow-x: hidden;
	transition: background-color 1s ease;
}

.exercise[data-done="yes"] {
	background-color: var(--green-bg-color);
}

.exercise h1 {
	font-size: 85px;
	margin: 0;
}

.exercise h2 {
	font-size: 72px;
}

.exerciseInfo {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
	line-height: 2em;
}

.exerciseInfo .repSets {
	margin-top: 1.1em;
}

.exerciseInfo .setText {
	background-color: var(--set-color);
	border: var(--set-color-fix) 3px solid;
	padding-left: 10px;
	padding-right: 15px;
}

.exerciseInfo .repText {
	background-color: var(--rep-color);
	border: var(--rep-color-fix) 3px solid;
	padding-left: 10px;
	padding-right: 15px;
}

.bottomActions {
	position: absolute;
	right: 0;
	bottom: 0;

	margin-right: 2em;
	margin-bottom: 2em;

	transform: scale(2);
	transform-origin: 100% 100%;

	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: flex-end;
	align-content: flex-start;
}

.bottomActions button {
	margin-bottom: 0.3em;
	font-size: 1.5em;
}

.bottomActions .rowActions {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: flex-start;
	align-content: flex-start;
}

.bottomActions .rowActions button {
	margin-bottom: 0;
	margin-left: 0.15em;
}

.bottomActions .rowActions svg {
	width: 1.5em;
	height: 1.5em;
}

.exerciseInfo .timeTexts {
	margin-top: 1.333em;
}

.exerciseInfo .timeTexts span {
	background-color: var(--time-color);
	border: var(--time-color-fix) 3px solid;
	padding-left: 10px;
	padding-right: 15px;
}

.topInfo {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;

	background-color: var(--light-bg-color);
	padding: 7px 7px 7px 10px;
	text-align: left;
	font-size: 20px;
}

.explainer {
	overflow: auto;
	margin-right: 0;
}

.explainer .images-masonry {
	display: flex;
	width: auto;
}

.explainer img {
	width: 100%;
}